html,
body,
#root {
  margin: 0;
  width: 100%;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.viewport-height {
  height: 100vh;
}

.white-bg {
  background: #ffffff;
}

.top-element {
  margin-top: 16px !important;
}

.site-layout-content {
  padding: 15px;
  max-width: 900px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.footer {
  padding-top: 50px;
  max-width: 900px;
  margin: 0 auto;
  bottom: 0;
}

.header {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: background-color 0.25s;
  margin: 0 auto;
  width: 100%;
  background-color: #1f3a50;
  padding-left: 50px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

h1 {
  color: #1f3a50;
  font-size: 1.5em;
}

h2 {
  color: #1f3a50;
  font-size: 1.4em;
}

h3 {
  color: #1f3a50;
  font-size: 1.2em;
}

h4 {
  color: #1f3a50;
  font-size: 1.1em;
}

.divider {
  margin: 0 50%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
}

.pricing-breakdown {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 15px;
  margin-top: 15px;
}

.leyend {
  font-size: 0.9em;
  font-weight: bold;
}

.margin-botton-5 {
  margin-bottom: 5px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-25 {
  margin-top: 25px;
}

.total-box-leyend {
  background-color: #f6fafd;
  margin-top: 15px;
  padding: 15px;
}

.site-layout {
  padding: "40px 60px 45px";
  margin-top: 15px;
}

.errors-block {
  color: red;
  padding-top: 15px;
  border: 1px solid #eee;
  margin-top: 5px;
  margin-bottom: 5px;
}

.step3-container {
  text-align: center;
  min-height: 200px !important;
}
