.m-t-10{
    margin-top: 10px;
}
.cardImgRow{
    margin-top: -25px;
}

.cardImg{
    width: 35px;
    height: 35px;
    margin-top: -5px;
}

.f-r{
    float: right;
    margin-top: 5px;
}

.error-input{
    border-color: #f5222d;
}

.row-error{
    margin-top: -15px;
    margin-bottom: 10px;
}

.error-text{
    color : red;
}